import React from "react"
import { Link, graphql } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"
import styles from "./people.module.scss"

export const query = graphql`
  query {
    craft {
      page: entry(section: "peopleIndex") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_peopleIndex_peopleIndex_Entry {
          alternateTitle
        }
      }
      people: entries(section: "people", orderBy: "title asc") {
        id
        title
        uri
        ... on Craft_people_person_Entry {
          nameFirstLine
          nameSecondLine
          portrait {
            id
            url
            width
            height
            focalPoint
            ... on Craft_people_Asset {
              altText
            }
          }
        }
      }
    }
  }
`

const PeopleIndex = ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic
  const people = data.craft.people

  return (
    <Layout seo={seo} mode="zeus">
      <div className="page-content-module mb-10">
        <div className="wrapper">
          <div className={styles.title}>
            <div className="columns">
              <div className="column is-12 is-12-tablet is-8-desktop is-6-widescreen">
                <h1
                  className="h-1 h--cms-title"
                  dangerouslySetInnerHTML={{
                    __html: page.alternateTitle
                      ? page.alternateTitle
                      : page.title,
                  }}
                ></h1>
              </div>
            </div>
          </div>
          <div>
            <ol className="columns is-multiline">
              {people.map(person => {
                const portrait = person.portrait[0]
                return (
                  <li
                    key={`person-${person.id}`}
                    className="column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen"
                  >
                    <Link
                      className={`${styles.person} img-tile`}
                      to={`/${person.uri}`}
                    >
                      <div className={styles.personImage}>
                        <div className="img-tile-image">
                          <Image
                            height={portrait.height}
                            width={portrait.width}
                            sizes="(min-width: 1216px) 306px, (min-width: 1024px) 352px, (min-width: 768px) 450px, calc(100vw - 2.5rem)"
                            crop={true}
                            url={portrait.url}
                            aspectRatio="2:3"
                            focalPoint={portrait.focalPoint}
                            altText={portrait.altText}
                          />
                        </div>
                      </div>
                      <div className="body-large">
                        <span className="img-tile-title-gradient">
                          {person.nameFirstLine}{" "}
                          {/* Only first line is required. */}
                          {person.nameSecondLine && person.nameSecondLine}
                        </span>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PeopleIndex
